import React, { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";

import { BsFillCartDashFill, BsFillCartPlusFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addToSingleCart } from "../features/products/cartSlice";

import TagManager from "react-gtm-module";
import { singleProductFetching } from "../features/products/singleProductSlice";
import { thumbImageFetching } from "../features/products/thumbImageSlice";
import { Triangle } from "react-loader-spinner";

const OrderNowModal = ({ product, handleClose }) => {
  // const { items: size } = useSelector((state) => state.sizes);
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );

  const { items: singleProduct, loader } = useSelector(
    (state) => state.singleProduct
  );

  const { items: thumbImage } = useSelector((state) => state.thumbImage);
  console.log(data);
  console.log(cartTotalAmount);

  // const [color, setColor] = useState([]);

  const [cartQuantity, setCartQuantity] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  console.log(selectedOption);

  // const [size, setSize] = useState([]);

  // const [colorAtr, setColorAtr] = useState("");

  // const [sizeAtr, setSizeAtr] = useState("");

  const [response, setResponse] = useState({});
  const [size, setSize] = useState([]);

  const dispatch = useDispatch();
  const navigation = useNavigate();

  // const state = {
  //   button: "add_to_cart",
  // };

  // const value = product.response * product.cartQuantity;

  // const delay = async (ms) => {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // };

  // const clearFunction = () => {
  //   setResponse("");
  // };

  useEffect(() => {
    if (selectedOption) {
      fetch(`${process.env.REACT_APP_URL}/api-findproductstock`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productid: product?.id,
          variantid: selectedOption,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data as needed
          console.log("Response from server:", data);
          setResponse(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedOption, product?.id]);

  const handleRadioClick = (option) => {
    setSelectedOption(option);
    setCartQuantity(1);
  };

  useEffect(() => {
    const SizeAttribute = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-specificattribute/${product?.id}`
      );
      setSize(res.data);
      if (res.data.length > 0) {
        setSelectedOption(res.data[0].variant);
      }
    };
    SizeAttribute();
  }, [product?.id]);

  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity((prev) => prev + 1);
    toast.warn("Quantity Increased", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);

  const handleBuyNow = (product) => {
    if (!selectedOption) {
      toast.warn("Select your product size plse", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // dispatch(removeAllFromCart());
      if (response.discount_price > 0) {
        dispatch(
          addToSingleCart({
            ...product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.discount_price,
          })
        );
      }
      if (response.price > 0 && response.discount_price < 1) {
        dispatch(
          addToSingleCart({
            ...product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.price,
          })
        );
      }
      navigation("/checkout");
    }

    const value =
      response.discount_price > 0 ? response?.discount_price : response?.price;
    console.log(value);

    TagManager.dataLayer({
      dataLayer: {
        event: "add_to_cart", // Event name
        buttonName: "add_to_cart", // Additional data

        ecommerce: {
          currency: "BDT",

          value: value * cartQuantity,

          itmes: [
            {
              item_name: product.name,
              item_id: product.id,
              price:
                product.after_discount > 0
                  ? product.after_discount
                  : product.unit_price,
              sizewise_price: product.response,
              item_category: product.category_name,
              item_subcategory: product.subcategory_name,
              quantity: cartQuantity,
              size: selectedOption,
            },
          ],
        },
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "begin_checkout", // Event name
        buttonName: "begin_checkout", // Additional data
        ecommerce: {
          currency: "BDT",

          value: value * cartQuantity,

          itmes: [
            {
              item_name: product.name,
              item_id: product.id,
              price:
                product.after_discount > 0
                  ? product.after_discount
                  : product.unit_price,
              sizewise_price: product.response,
              item_category: product.category_name,
              item_subcategory: product.subcategory_name,
              quantity: cartQuantity,
              size: selectedOption,
            },
          ],
        },
      },
    });
  };
  const handleAddToCart = () => {
    const value =
      response.discount_price > 0 ? response?.discount_price : response?.price;
    TagManager.dataLayer({
      dataLayer: {
        event: "add_to_cart", // Event name
        buttonName: "add_to_cart", // Additional data

        ecommerce: {
          currency: "BDT",

          value: value * cartQuantity,

          itmes: [
            {
              item_name: product.name,
              item_id: product.id,
              price:
                product.after_discount > 0
                  ? product.after_discount
                  : product.unit_price,
              sizewise_price:
                response.discount_price > 0
                  ? response?.discount_price
                  : response?.price,
              item_category: product.category_name,
              item_subcategory: product.subcategory_name,
              quantity: cartQuantity,
              size: selectedOption,
            },
          ],
        },
      },
    });
    if (!selectedOption) {
      toast.warn("Select your product size plse", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // dispatch(removeAllFromCart());
      if (response.discount_price > 0) {
        dispatch(
          addToSingleCart({
            ...product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.discount_price,
          })
        );
      }
      if (response.price > 0 && response.discount_price < 1) {
        dispatch(
          addToSingleCart({
            ...product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.price,
          })
        );
      }
      // navigation("/checkout");
      setCartQuantity(1);
    }
  };

  // const handleAddToCart = () => {
  //   if (!selectedOption) {
  //     toast.warn("Select your product size plse", {
  //       position: "bottom-left",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   } else {
  //     dispatch(
  //       addtoCart({
  //         ...product,
  //         cartQuantity,
  //         sizeAtr: selectedOption,
  //         response: response.price,
  //       })
  //     );
  //     setSelectedOption(null);
  //   }
  // };
  useEffect(() => {
    dispatch(singleProductFetching(product?.id));
    dispatch(thumbImageFetching(product?.id));
  }, [dispatch, product?.id]);
  return (
    <>
      <div
        onClick={handleClose}
        className="bg-black/40   z-[9998]  fixed top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden"
      ></div>
      <div className="p-2 z-[9999] bg-white  fixed  shadow-xl top-0 right-0 left-0 bottom-0 w-full h-auto mr-5  md:container md:mx-auto my-10 overflow-y-scroll">
        <div className="flex items-center justify-end">
          <button
            onClick={handleClose}
            className="my-2 flex items-end justify-end"
          >
            <RxCross1 />
          </button>
        </div>
        {!loader ? (
          <div className="flex flex-col xl:flex-row md:px-10 gap-[2rem] lg:gap-[3rem] p-3 justify-center">
            <div className="left image flex  justify-center  mt-3 px-2 md:px-0 ">
              <ProductImageCarasol
                productImg={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
                thumbImage={thumbImage}
              />
            </div>
            <div className="product-details lg:w-[40rem] flex flex-col gap-3  p-2">
              <div className="text-2xl font-semibold">{product?.name}</div>
              {/* size and color */}

              {/* product quentity */}

              <div className="relative w-full rounded-md overflow-hidden">
                <input
                  type="number"
                  value={cartQuantity}
                  onChange={(e) => setCartQuantity(e.target.value)}
                  className="w-full py-3 text-center border border-red-200"
                />

                {/* <span>{cartQuantity}</span> */}
                <button
                  onClick={() => handleDecrease(product)}
                  className="absolute top-0 bottom-0 left-0 flex items-center justify-center bg-red-600 p-5 text-white"
                >
                  <span>
                    <BsFillCartDashFill />
                  </span>
                </button>
                <button
                  onClick={() => handleIncrease(product)}
                  className="absolute top-0 bottom-0 right-0 flex items-center justify-center bg-red-600 p-5 text-white"
                >
                  <span>
                    <BsFillCartPlusFill />
                  </span>
                </button>
              </div>

              {/* add to cart and bye now button */}

              <div>
                <div className="flex flex-col ">
                  <>
                    <div className="flex flex-col  gap-5 mb-5">
                      {size?.length > 0 && (
                        <div className="color-atr flex items-center justify-start gap-7 mb-2">
                          <h4 className="text-2xl">
                            <span>Available size</span>
                            <span>:</span>
                          </h4>
                          <div className="flex flex-wrap items-center justify-start gap-4">
                            {size?.map((s, index) => (
                              <span key={s.id}>
                                <button
                                  key={index}
                                  onClick={() => handleRadioClick(s.variant)}
                                  className={`border border-red-500 py-3 px-4 ${
                                    selectedOption === s.variant
                                      ? "bg-red-600 text-white"
                                      : ""
                                  }`}
                                >
                                  {s.variant}
                                </button>
                              </span>
                            ))}
                          </div>
                        </div>
                      )}

                      <div className="flex gap-5 items-center">
                        <h4 className="text-2xl">Price :</h4>
                        <span className="text-red-500 font-normal text-3xl">
                          {response.price > 0 ? (
                            <>
                              <div className="flex gap-5">
                                {response.discount_price > 0 ? (
                                  <div className="flex gap-5">
                                    <span className="font-normal line-through text-black decoration-200">
                                      ৳ {+response?.price}
                                    </span>
                                    <span className="text-red-600 font-normal">
                                      ৳{" "}
                                      {+response.discount_price * cartQuantity}
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="text-red-600 font-normal">
                                      ৳ {+response?.price * cartQuantity}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="text-red-600 font-light text-xl">
                              <span>select your product size</span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col gap-5">
                      <button
                        disabled={!response?.price}
                        onClick={handleAddToCart}
                        className={`${
                          !response?.price
                            ? "bg-red-600 cursor-not-allowed"
                            : "bg-red-600 hover:bg-[#3A3A3A]"
                        }  p-4 w-full rounded-md  text-white duration-300`}
                      >
                        Add to cart
                      </button>
                      <button
                        disabled={!response?.price}
                        className={` ${
                          response.price
                            ? "bg-red-600 hover:bg-[#3A3A3A]"
                            : "bg-red-600 cursor-not-allowed"
                        }    p-4 w-full rounded-md  text-white duration-300`}
                        onClick={() => handleBuyNow(product)}
                      >
                        Buy Now
                      </button>
                    </div>
                  </>
                </div>
              </div>

              {/* short descriptin */}

              <div></div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center min-h-[calc(100vh-20rem)]">
            <Triangle height="80" width="80" color="#C3161C" />
          </div>
        )}
      </div>
    </>
  );
};
export const ProductImageCarasol = ({ thumbImage, productImg }) => {
  const [mainImage, setMainImage] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [selectedThumbIndex, setSelectedThumbIndex] = useState(0);
  const thumbnailContainerRef = useRef(null);

  useEffect(() => {
    if (productImg) {
      setMainImage(productImg);
    }
  }, [productImg]);

  const handleMouseEnter = () => {
    setZoomLevel(2);
  };

  const handleMouseLeave = () => {
    setZoomLevel(1);
    setZoomPosition({ x: 0, y: 0 });
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  const handleThumbnailClick = (index) => {
    setSelectedThumbIndex(index);
    setMainImage(
      `${process.env.REACT_APP_URL}/uploads/product/${thumbImage[index]?.icon}`
    );
  };

  const scrollThumbnails = (direction) => {
    const container = thumbnailContainerRef.current;
    if (direction === "left") {
      container.scrollBy({ left: -100, behavior: "smooth" });
    } else if (direction === "right") {
      container.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  return (
    <div className="relative  w-auto md:w-[25rem]  lg:w-[40rem]">
      <div
        className="w-full h-[25rem] md:h-[30rem] lg:h-[33rem] 2xl:h-[38rem]  overflow-hidden"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        {mainImage && (
          <img
            src={mainImage}
            alt="Product"
            className="object-fit w-full h-full overflow-hidden cursor-pointer"
            style={{
              transform: `scale(${zoomLevel})`,
              transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
            }}
          />
        )}
      </div>
      <div className="flex mt-4 space-x-2 items-center">
        {thumbImage.length > 4 && (
          <button
            className="bg-gray-300 text-black p-2 rounded-full"
            onClick={() => scrollThumbnails("left")}
          >
            {"<"}
          </button>
        )}
        <div
          ref={thumbnailContainerRef}
          className="flex overflow-x-scroll space-x-2 w-[calc(100%-4rem)] scrollbar-hide"
        >
          {thumbImage.map((image, index) => (
            <img
              key={index}
              src={`${process.env.REACT_APP_URL}/uploads/product/${image?.icon}`}
              alt={`Thumbnail ${index}`}
              className={`w-[80px] h-[80px] object-cover cursor-pointer ${
                selectedThumbIndex === index ? "border-2 border-blue-500" : ""
              }`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
        {thumbImage.length > 4 && (
          <button
            className="bg-gray-300 text-black p-2 rounded-full"
            onClick={() => scrollThumbnails("right")}
          >
            {">"}
          </button>
        )}
      </div>
    </div>
  );
};

export default OrderNowModal;
