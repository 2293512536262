import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: {},
  status: "idle",
  error: null,
  loader: true,
};

//data fetching

export const singleProductFetching = createAsyncThunk(
  "singleProduct/productFetching",
  async (productId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/api-products/${productId}`
    );
    return res.data;
  }
);

export const singleProductSlice = createSlice({
  name: "singleProduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(singleProductFetching.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(singleProductFetching.fulfilled, (state, action) => {
      state.loader = false;
      state.items = action.payload;
    });
    builder.addCase(singleProductFetching.rejected, (state, action) => {
      // state.status = "Failed";
      state.error = action.error.message;
    });
  },
});

export default singleProductSlice.reducer;
