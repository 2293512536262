import { useDispatch } from "react-redux";
import { AiOutlineHeart } from "react-icons/ai";
import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import OrderNowModal from "./OrderNowModal";
import TagManager from "react-gtm-module";
const Card = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToCartHandler = (product) => {
    dispatch(addtoCart(product));
    navigate("/checkout");
  };

  const viewItem = (product) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "view_item", // Event name
        buttonName: "viewitem", // Additional data

        ecommerce: {
          currency: "BDT",
          value:
            product.after_discount > 0
              ? product.after_discount
              : product.unit_price,
          itmes: [
            {
              item_name: product.name,
              item_id: product.id,
              price:
                product.after_discount > 0
                  ? product.after_discount
                  : product.unit_price,
              discount_price: product.after_discount,
              item_category: product.category_name,
              item_subcategory: product.subcategory_name,
              item_discount: product.unit_price - product.after_discount,
            },
          ],
        },
      },
    });

    navigate(`/productdetails/${product?.id}`);
  };

  const [cartQuantity, setCartQuantity] = useState(1);
  const [modal, setModal] = useState(false);

  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(addToSingleCart({ ...product, cartQuantity }));
    navigate("/checkout");
  };

  const handleClose = () => {
    setModal(!modal);
  };

  const handleModalOpen = (product) => {
    console.log(product);
    setModal(!modal);
    TagManager.dataLayer({
      dataLayer: {
        event: "view_item", // Event name
        buttonName: "viewitem", // Additional data

        ecommerce: {
          currency: "BDT",
          value:
            product.after_discount > 0
              ? product.after_discount
              : product.unit_price,
          itmes: [
            {
              item_name: product.name,
              item_id: product.id,
              price:
                product.after_discount > 0
                  ? product.after_discount
                  : product.unit_price,
              discount_price: product.after_discount,
              item_category: product.category_name,
              item_subcategory: product.subcategory_name,
              // item_discount: product.unit_price - product.after_discount,
            },
          ],
        },
      },
    });
  };

  return (
    <div className="flex flex-col justify-between  bg-white border-2 group-hover:border-[#C3161C] rounded-xl duration-300">
      <Link
        onClick={() => viewItem(product)}
        to={`/productdetails/${product?.id}`}
        className=""
      >
        <div className="image-section p-2">
          <div className=" h-[10rem] md:h-[15rem] lg:h-[18rem] 2xl:[20rem] overflow-hidden">
            <img
              src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        <div className="product-details text-sm md:text-lg flex flex-col gap-2   p-2">
          <div className=" hover:text-[#C3161C] duration-300">
            {product?.name}
          </div>
          <div className="font-normal">
            <span>৳</span>
            <span>{product?.unit_price}</span>
          </div>
        </div>
      </Link>

      <div className="items-end ">
        <div className="flex justify-between">
          <button
            onClick={() => handleModalOpen(product)}
            className="py-1 items-center justify-center text-sm font-extralight border border-[#C3161C] text-[#C3161C] rounded-bl-xl w-[7rem] lg:w-36 hidden lg:block"
          >
            অর্ডার করুন
          </button>
          {/* mobile device */}
          <Link
            onClick={() => viewItem(product)}
            to={`/productdetails/${product?.id}`}
            className="py-1 items-center justify-center text-center text-sm font-extralight border border-[#C3161C] text-[#C3161C] rounded-bl-xl w-[7rem] lg:w-36 block lg:hidden"
          >
            অর্ডার করুন
          </Link>
          {modal && (
            <>
              <OrderNowModal
                product={product}
                modal={modal}
                setModal={setModal}
                handleClose={handleClose}
              />
            </>
          )}

          <div className="text-[#C3161C] pr-2 flex items-center justify-start">
            <AiOutlineHeart className="text-2xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
