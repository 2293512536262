import React from "react";
import SectionTitle from "../components/SectionTitle";
import { Link } from "react-router-dom";

const TermsConditions = () => {
  return (
    <div className="container mx-auto px-2">
      <SectionTitle title={"Terms And Conditions"} />
    </div>
  );
};

export default TermsConditions;
