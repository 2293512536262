import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { SlArrowRightCircle } from "react-icons/sl";

import {
  // addToSingleCart,
  addtoCart,
  decreaseCart,
  getSubtotal,
  removeAllFromCart,
  removeFromCart,
  // removeFromCart,
} from "../features/products/cartSlice";

// import { currencyFormatter } from "../utils/currencyFormatter";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import TagManager from "react-gtm-module";

// import Marquee from "react-fast-marquee";

const Checkout = () => {
  const navigate = useNavigate();
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );

  // const [orderItem, setOrderItem] = useState(data);

  const [coupon, setCoupon] = useState(0);

  // console.log(JSON.stringify(orderItem).split(" "));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubtotal());
  }, [data, dispatch]);
  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    shipping_cost: "",
    shipping_type: "",
    baby_age: "",
  });

  console.log(formData.shipping_type);
  const [couponCode, setCouponCode] = useState("");

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleDecrease = (product) => {
    if (product?.cartQuantity > 1) {
      dispatch(decreaseCart(product));
    }
  };

  const handleIncrease = (product) => {
    dispatch(addtoCart(product));
    // product.cartQuantity++;
  };

  const handleRemove = (product) => {
    dispatch(removeFromCart(product));
  };

  const handleDlivarry = (e) => {
    setFormData({
      ...formData,
      shipping_cost: e.target.value,
    });
  };

  const handleGoToHome = () => {
    navigate("/");
  };
  const applyCoupon = async () => {
    setCouponCode("");
    try {
      await fetch(`${process.env.REACT_APP_URL}/api-coupon-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coupon: couponCode,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setCoupon(data);
        });
    } catch (err) {
      console.log(err.message);
    }
  };
  let grand_total = coupon.discount
    ? cartTotalAmount + +formData.shipping_cost + -coupon.discount
    : cartTotalAmount + +formData.shipping_cost + 0;
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    TagManager.dataLayer({
      dataLayer: {
        event: "purchase", // Event name
        buttonName: "purchase",
        ecommerce: {
          shipping: formData.shipping_cost,
          value: cartTotalAmount,
          currency: "BDT",
          itmes: data.map((product) => ({
            item_name: product.name,
            item_id: product.id,
            price:
              product.after_discount > 0
                ? product.after_discount
                : product.unit_price,

            sizewise_price: product.response,
            item_category: product.category_name,
            item_subcategory: product.subcategory_name,
            quantity: product.cartQuantity,
            size: product.sizeAtr,
          })),
        },
        customer: {
          name: formData.name,
          billingaddress: {
            address: formData.address,
          },
          phone: formData.phone,
          payment_type: formData.shipping_type,
        },
      },
    });

    if (formData.shipping_type === "Cash On Delivery") {
      try {
        const res = await fetch(`${process.env.REACT_APP_URL}/place-order`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            item: data,
            shipping_cost: formData.shipping_cost,
            shipping_type: formData.shipping_type,
            baby_age: formData.baby_age,
            grand_total,
          }),
        });

        if (!res.ok) throw new Error("Something Went Error");
        if (res.ok) {
          dispatch(removeAllFromCart());
          navigate("/successpage");
        }
      } catch (err) {
        console.log(err.message);
      }
    }

    if (formData.shipping_type === "bkash") {
      const queryParams = new URLSearchParams({
        name: formData.name,
        item: JSON.stringify(data),
        // email: formData.email,
        phone: formData.phone,
        address: formData.address,
        amount: grand_total,
        // shipping_type: formData.shipping_type,
        // area: formData.area,
        baby_age: formData.baby_age,
        shipping_cost: formData.shipping_cost,
      });

      // window.location.href = `https://sombhaar.com/admin/public/bkash/pay/?${queryParams}`;
      window.location.href = `${process.env.REACT_APP_URL}/bkash/pay/?${queryParams}`;
      // try {
      //   const res = await fetch(`${process.env.REACT_APP_URL}/bkash/create`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       // name: formData.name,
      //       // email: formData.email,
      //       // phone: formData.phone,
      //       // address: formData.address,
      //       // item: data,
      //       // shipping_cost: formData.shipping_cost,
      //       // shipping_type: formData.shipping_type,
      //       // baby_age: formData.baby_age,
      //       amount: grand_total,
      //     }),
      //   });

      //   if (!res.ok) throw new Error("Something Went Error");
      //   if (res.ok) {
      //     // dispatch(removeAllFromCart());
      //     // navigate("/successpage");
      //   }
      // } catch (err) {
      //   console.log(err.message);
      // }
    }
  };

  return (
    <div>
      {data?.length >= 1 ? (
        <>
          <form
            onSubmit={handleFormSubmit}
            className="checkout-page mt-10 mb-10 container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-20 "
          >
            <div className="billing-address border border-black bg-white">
              <div className="billing-address flex flex-col gap-2 order-2 md:order-1 p-5">
                <div className="flex items-center justify-center bg-red-500 text-white">
                  <h2 className="text-base lg:text-2xl my-3 ">
                    অর্ডার করতে নিচের ফর্মটি পূরণ করুন
                  </h2>
                </div>
                <div className="form-control flex flex-col gap-2 mt-2">
                  <label className="">আপনার নাম</label>
                  <input
                    required
                    type="text"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    value={formData.name}
                    className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
                    placeholder="আপনার নাম লিখুন"
                  />
                </div>
                <div className="form-control flex flex-col my-2">
                  <label className="">আপনার মোবাইল নাম্বার</label>
                  <input
                    required
                    type="tel"
                    pattern="[0-9]{11}"
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                    value={formData.phone}
                    className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300"
                    placeholder="আপনার নাম্বার লিখুন"
                  />
                </div>

                <div className="form-control flex flex-col  mb-3 ">
                  <label className="mb-2">আপনার ঠিকানা</label>
                  <textarea
                    required
                    type="text"
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                    value={formData.address}
                    className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300 resize-none"
                    placeholder="আপনার ঠিকানা লিখুন"
                  />
                </div>

                <div className="form-control flex flex-col  mb-3 ">
                  <label className="mb-2">আপনার বাচ্চার বয়স</label>
                  <input
                    required
                    type="text"
                    onChange={(e) =>
                      setFormData({ ...formData, baby_age: e.target.value })
                    }
                    value={formData.baby_age}
                    className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300 resize-none"
                    placeholder="আপনার বাচ্চার বয়স লিখুন"
                  />
                </div>

                <div className="flex items-center justify-center bg-red-500 text-white py-3">
                  <span className="text-base">ডেলিভারি চার্জ</span>
                </div>

                <div
                  className=" flex gap-3 items-center justify-center my-3"
                  onChange={(e) => handleDlivarry(e)}
                  required
                >
                  <label className="flex gap-3">
                    <input
                      required
                      type="radio"
                      value="80"
                      name="shipping_cost"
                      className="peer sr-only"
                    />
                    <div className="px-6 py-3 cursor-pointer rounded-md bg-white p-1 text-gray-600 ring-1  transition-all hover:shadow  peer-checked:ring-red-600 peer-checked:ring-offset-2 peer-checked:bg-red-600 peer-checked:text-white">
                      <div className="flex flex-col items-center justify-center">
                        <span>Inside Dhaka</span>
                        <span>80</span>
                      </div>
                    </div>
                  </label>
                  <label className="flex gap-3">
                    <input
                      required
                      type="radio"
                      value="150"
                      name="shipping_cost"
                      className="peer sr-only"
                    />
                    <div className="px-6 py-3 cursor-pointer rounded-md bg-white p-1 text-gray-600 ring-1  transition-all hover:shadow peer-checked:ring-red-600 peer-checked:ring-offset-2 peer-checked:bg-red-600 peer-checked:text-white">
                      <div className="flex flex-col items-center justify-center">
                        <span>Outside Dhaka</span>
                        <span>150</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="flex  items-center justify-center bg-red-500 text-white py-3">
                  <span className="text-base">পেমেন্ট পদ্ধতি</span>
                </div>
                <div className="shipping_Type  flex justify-center items-end py-3   gap-5 ">
                  {/* <div className="flex items-center gap-3">
              <input
                required
                onChange={(e) =>
                  setFormData({ ...formData, shipping_type: e.target.value })
                }
                type="checkbox"
                value="Cash On Delivery"
                name=""
                id=""
              />
              <span className="text-[0.9rem] text-[#212121]">
                Cash On Delivery
              </span>
            </div> */}

                  <div>
                    <label className="cursor-pointer">
                      <input
                        type="radio"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            shipping_type: e.target.value,
                          })
                        }
                        name="shipping_type"
                        value={"Cash On Delivery"}
                        className="peer sr-only"
                        required
                      />
                      <div className="w-full md:w-36 2xl:w-40  rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-2">
                        <img
                          src="./cash_on_delivery.webp"
                          alt=""
                          className="w-full h-12 "
                        />
                      </div>
                    </label>
                  </div>
                  <div>
                    <label className="cursor-pointer relative">
                      <input
                        type="radio"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            shipping_type: e.target.value,
                          })
                        }
                        className="peer sr-only"
                        name="shipping_type"
                        value={"bkash"}
                        required
                      />
                      <div className=" w-full md:w-36 2xl:w-40  rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-2">
                        {/* <p class="text-sm font-semibold uppercase text-gray-500">
                        Cash On Delivery
                      </p> */}
                        <img
                          src="./bkash.svg"
                          alt=""
                          className="w-full h-12 "
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="form-control flex items-center justify-center">
                  <button
                    type="submit"
                    className="bg-[#C3161C] px-5 py-4 rounded-md text-2xl text-white hover:bg-transparent hover:text-slate-700 border hover:border-slate-700 duration-300"
                  >
                    অর্ডার কনফার্ম করুন
                  </button>
                </div>
              </div>
            </div>

            <div className="order-details  md:order-2 order-1 border border-black bg-white p-5">
              <div className="flex justify-center mb-5 underline underline-offset-8 text-red-400">
                <h2 className="text-2xl">আপনার অর্ডার</h2>
              </div>

              <div className="h-auto overflow-y-scroll order-details-container">
                {data?.map((product) => (
                  <div
                    key={product.id}
                    className="cart-items  h-20  flex  items-start gap-4 mt-3 overflow-hidden"
                  >
                    <div className="cart-img aspect-square w-20 flex justify-center items-center overflow-hidden ">
                      <img
                        src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                        className="w-full object-cover "
                        alt=""
                      />
                    </div>
                    <div className="cart-description w-full overflow-hidden ">
                      <div className="flex flex-col">
                        <p className="truncate">
                          {product?.name?.substring(0, 60)} /
                          <span>{product?.sizeAtr}</span>
                        </p>
                        <div className="flex gap-5 items-center my-3">
                          <div className="cart-price">
                            {product?.response * product?.cartQuantity} ৳
                          </div>
                          <div>
                            <div className="">
                              <div className="counter flex">
                                <button
                                  onClick={() => handleDecrease(product)}
                                  className="h-10 w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50"
                                >
                                  -
                                </button>
                                <span className="h-10 w-10 bg-gray-100 flex justify-center items-center border border-gray-300">
                                  {product?.cartQuantity}
                                </span>
                                <button
                                  onClick={() => handleIncrease(product)}
                                  className="h-10 w-10 bg-gray-100 border border-gray-300 active:bg-gray-700 active:text-gray-50"
                                >
                                  +
                                </button>

                                <div className="flex items-center justify-center px-5">
                                  <button
                                    className="text-base hover:text-red-500 duration-500 justify-center"
                                    onClick={() => handleRemove(product)}
                                  >
                                    <RiDeleteBin6Line />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <button
                      className="text-base hover:text-red-500 duration-500 justify-center"
                      onClick={() => handleRemove(product)}
                    >
                      <RiDeleteBin6Line />
                    </button> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="payment-summary flex flex-col gap-3 mt-5 ">
                <div className="sub-total flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold">
                    Sub Total
                  </h4>
                  <span className="text-slate-700 ">{cartTotalAmount} ৳</span>
                </div>
                <div className="shipping flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold ">
                    Shipping
                  </h4>
                  <span className="text-slate-700 ">
                    {+formData.shipping_cost} ৳
                  </span>
                </div>
                <div className="shipping flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold ">
                    Discount
                  </h4>
                  <span className="text-slate-700 ">
                    {coupon.discount ? +coupon.discount : 0} ৳
                  </span>
                </div>
                <div className="bg-black h-1"></div>
                <div className="sub-total flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold">
                    Total
                  </h4>
                  <span className="text-slate-700 font-semibold ">
                    {grand_total} ৳
                  </span>
                </div>
                {/* <div>
              <div className="flex   gap-3">
                <input
                  type="text"
                  value={couponCode}
                  onChange={handleCouponCodeChange}
                  className="px-3 py-2 xl:w-72  w-60  rounded-md outline-none border"
                />
                <button
                  onClick={applyCoupon}
                  className="bg-[#0ea5e9] px-3  text-sm rounded-md"
                >
                  Apply Coupon
                </button>
              </div>
              {response.status === 200 && (
                <p className="text-green-500 text-xs mt-2">
                  {response.massage}
                </p>
              )}
              {response.status === 404 && (
                <p className="text-rose-500 text-xs mt-2">{response.massage}</p>
              )}
            </div> */}
              </div>
            </div>
          </form>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <div className="w-full h-40 mt-10 flex items-center justify-center">
            <span className="text-4xl">No Product for checkout !!!</span>
          </div>
          <div>
            <button
              onClick={handleGoToHome}
              className={` p-4 w-full rounded-md bg-red-600 hover:bg-[#3A3A3A]  text-white duration-300 flex items-center justify-center gap-5`}
            >
              continue shopping
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;
