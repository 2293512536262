import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalAmount: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,

  reducers: {
    // addtoCart: (state, action) => {
    //   //specific product
    //   const item = state.cartItems.find(
    //     (product) =>
    //       product.id === action.payload.id &&
    //       product?.response === action?.payload?.response
    //   );

    //   //increase quantity
    //   if (item?.response === action?.payload?.response) {
    //     // item.cartQuantity += action.payload.cartQuantity;
    //     // item.cartQuantity += action.payload.cartQuantity;
    //     item.sizeAtr = action.payload.sizeAtr;
    //     item.response = action.payload.response;

    //     if (item.cartQuantity) {
    //       item.cartQuantity += 1;
    //     }

    //     toast.info("Quantity Increased", {
    //       position: "bottom-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //     });
    //   } else {
    //     // add to cart
    //     const assemblingItem = { ...action.payload, cartQuantity: 1 };
    //     toast.success("Product added", {
    //       position: "bottom-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //     });
    //     state.cartItems.push(assemblingItem);
    //   }
    //   // state.cartItems.push(item);
    //   //updated local storage
    //   localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    // },

    addtoCart(state, action) {
      //if the item already in the cart
      const existedItemIndex = state.cartItems.findIndex(
        (item) =>
          item.id === action.payload.id &&
          item?.sizeAtr === action?.payload?.sizeAtr
      );

      //if exist
      if (existedItemIndex >= 0) {
        //increase quantity
        state.cartItems[existedItemIndex].cartQuantity += 1;
        toast.info("Quantity Increased", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        //add to cart
        const assemblingItem = { ...action.payload, cartQuantity: 1 };
        toast.success("Product added", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        state.cartItems.push(assemblingItem);
      }
      //set data in local storage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    removeFromCart(state, action) {
      //filter item which are not deleted
      const updatedCartItem = state.cartItems.filter(
        (item) => item?.sizeAtr !== action?.payload?.sizeAtr
      );

      toast.error("Product Removed", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //update the item
      state.cartItems = updatedCartItem;

      //updated local storage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeAllFromCart(state, action) {
      state.cartItems = [];
      // toast.error("Cart Cleared", {
      //   position: "bottom-left",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
      //updated local storage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) =>
          item.id === action.payload.id &&
          item?.sizeAtr === action?.payload?.sizeAtr
      );
      //if item exist

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;

        toast.warn("Quantity Decreased", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const updatedCartItem = state.cartItems.filter(
          (item) => item.id !== action.payload.id
        );
        toast.error("Product Removed", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        state.cartItems = updatedCartItem;
      }
      //updated local storage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    getSubtotal(state, action) {
      const subTotal = state.cartItems.reduce((acc, item) => {
        const { response, cartQuantity } = item;
        console.log(response);
        const itemTotal = response * cartQuantity;
        acc += itemTotal;
        return acc;
      }, 0);

      state.cartTotalAmount = subTotal;
    },
    addToSingleCart: (state, action) => {
      const item = state.cartItems.find(
        (product) =>
          product.id === action.payload.id &&
          product?.sizeAtr === action?.payload?.sizeAtr
      );

      if (item) {
        item.cartQuantity += action.payload.cartQuantity;
        // item.colorAtr = action.payload.colorAtr;
      } else {
        toast.success("Product added", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        state.cartItems.push(action.payload);
      }
      //updated local storage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
  },
});

export const {
  addtoCart,
  removeFromCart,
  removeAllFromCart,
  decreaseCart,
  getSubtotal,
  addToSingleCart,
} = cartSlice.actions;

export default cartSlice.reducer;
